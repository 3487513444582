import React from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { MdFiberManualRecord } from 'react-icons/md';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import LocalizedLink from '../../utils/localized-link';
import LayoutWrapper from '../../components/layout';
import { dateToString } from '../../utils/date';
import { withForceUpdate } from '../../i18n/with-force-update';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  freelanceBadge: {
    background: `green`,
  },
  openSourceBadge: {
    background: `dodgerblue`,
  },
  employedBadge: {
    background: `slategrey`,
  },
}));

const ExperiencesPage = ({ data, pageContext, t }) => {
  const classes = useStyles();
  const [fullScreenImage, setFullScreenImage] = React.useState(null);

  const { language } = pageContext;

  React.useEffect(() => {
    if (window.location.hash) {
      setTimeout(() => {
        const yOffset = -90;
        const element = document.getElementById(window.location.hash.replace('#', ''));
        const y = element?.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 500);
    }
  }, []);

  return (
    <LayoutWrapper title={t('Experiences')} showTitle lang={language}>
      {fullScreenImage && <Lightbox mainSrc={fullScreenImage} onCloseRequest={() => setFullScreenImage(null)} />}
      <div
        css={{
          display: 'flex',
          flexDirection: `column`,
          alignContent: `center`,
          fontFamily: `LatoLatinWeb`,
          justifyContent: `space-evenly`,
          '& .vertical-timeline::before': {
            width: `3px`,
            background: `darkgray`,
          },
          '& .vertical-timeline-element-content': {
            background: `none`,
            boxShadow: `none`,
          },
          '& .vertical-timeline-element-icon': {
            background: `none`,
            boxShadow: `none`,
          },
          '& .vertical-timeline-element-date': {
            textTransform: `capitalize`,
            color: `darkgrey`,
          },
        }}
      >
        <VerticalTimeline animate={false}>
          {data.allStrapiExperiences.edges.map((document, itr) => (
            <VerticalTimelineElement
              key={`experience-${itr}`}
              id={document.node.slug}
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'rgb(66, 66, 66)', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid rgb(66, 66, 66)' }}
              date={`${dateToString(document.node.fromDate, language)} - ${
                document.node.stillActive ? t(`now`) : dateToString(document.node.toDate, language)
              }`}
              iconStyle={{ background: 'none', color: '#dfac47' }}
              icon={<MdFiberManualRecord />}
            >
              <h3 className="vertical-timeline-element-title" css={{ color: `#dfac47` }}>
                {document.node.name}
                <span
                  className={clsx({
                    [classes.freelanceBadge]: document.node.kindOfContract.toLowerCase() === 'freelance',
                    [classes.openSourceBadge]: document.node.kindOfContract.toLowerCase() === 'opensource',
                    [classes.employedBadge]: document.node.kindOfContract.toLowerCase() === 'employed',
                  })}
                  css={{
                    background: `grey`,
                    color: `white`,
                    borderRadius: `30px`,
                    fontFamily: `LatoLatinWebLight`,
                    textTransform: `uppercase`,
                    fontSize: `.65em`,
                    display: `inline-flex`,
                    marginLeft: `10px`,
                    padding: `4px 10px`,
                  }}
                >
                  {document.node.kindOfContract}
                </span>
              </h3>
              <h4 className="vertical-timeline-element-subtitle" css={{ color: `grey` }}>
                {document.node.City}
              </h4>

              <ReactMarkdown
                children={document.node.content}
                linkTarget="_blank"
                transformImageUri={(uri) => (uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`)}
              />
              {document.node.skills.map((skill, idx) => (
                <LocalizedLink
                  key={`skill-${idx}`}
                  css={{
                    borderRadius: `8px`,
                    border: `1px solid #dfac47`,
                    padding: `1px 5px`,
                    fontSize: `.7em`,
                    margin: `8px 4px`,
                    display: `inline-block`,
                  }}
                  to={`/about/skills#${skill.slug}`}
                >
                  {skill.name}
                </LocalizedLink>
              ))}
              <br />
              {document.node.experienceThumbnail && (
                <div
                  css={{
                    '@media (max-width: 768px)': {
                      textAlign: `center`,
                    },
                  }}
                >
                  <GatsbyImage
                    alt={document.node.name}
                    image={getImage(document.node.experienceThumbnail.localFile)}
                    onClick={() => setFullScreenImage(document.node.experienceThumbnail.localFile)}
                    imgStyle={{ objectFit: 'contain' }}
                    css={{
                      margin: `auto`,
                      width: `100%`,
                      '@media (max-width: 768px)': {
                        width: `100%`,
                        margin: `auto`,
                      },
                    }}
                  />
                </div>
              )}
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </LayoutWrapper>
  );
};

export default withForceUpdate(ExperiencesPage);

export const query = graphql`
  query($language: String) {
    allStrapiExperiences(sort: { order: DESC, fields: fromDate }, filter: { locale: { eq: $language } }) {
      edges {
        node {
          name
          content
          slug
          City
          stillActive
          kindOfContract
          fromDate
          toDate
          createdAt
          scopes {
            name
            description
            cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 500, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
                }
              }
            }
          }
          experienceThumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1000, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
              }
            }
          }
          skills {
            name
            slug
          }
        }
      }
    }
  }
`;
